.card-container {
    width: 325px;
    height: 350px;
    background-color: #222;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.social-container {
    height: 100%;
    width: 400px;
}

.card-image {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    height: 55%;
    background-size: cover;
    background-position: 50% 50%;
}

.card-description {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 45%;
    /* background-color: #fff; */
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    opacity: .75;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.card-description h4 {
    color: rgba(255, 255, 255, .75);
}

.card-description h5 {
    color: #ffc107;
}

@media screen and (max-width: 550px) {
    .card-container {
        width: 300px;
        height: 325px;
    }

    .work {
        padding-top: 5%;
    }

    .contact {
        padding-top: 15%;
    }
}


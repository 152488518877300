@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;300;400;500;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

a {
  text-decoration: none;
  color: var(--text);
}

:root {
  --text: #f7dffb;
  --background: #111;
  --primary: #2ccd1d;
  --secondary: #062814;
  --accent: #8ddb1f;

  /* sizing */
  --font-scale: 1.333;
  --p: 1rem;
  --h5: calc(var(--p) * var(--font-scale));
  --h4: calc(var(--h5) * var(--font-scale));
  --h3: calc(var(--h4) * var(--font-scale));
  --h2: calc(var(--h3) * var(--font-scale));
  --h1: calc(var(--h2) * var(--font-scale));
  --small: calc(var(--p) / var(--font-scale));
}

body {
  min-height: 100vh;
  background: var(--background);
  color: var(--text);
}

h1 {
  font-size: var(--h1);
}

h2 {
  font-size: var(--h2);
}

h3 {
  font-size: var(--h3);
}

h4 {
  font-size: var(--h4);
}

h5 {
  font-size: var(--h5);
}

p {
  font-size: var(--p);
}

.small {
  font-size: var(--small);
}
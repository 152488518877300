:root {
  --padding: 5%;
}

/* NAVBAR */

nav {
  position: fixed;
  top: 0;

  display: flex;
  width: 100%;
  height: 80px;

  align-items: center;
  justify-content: space-between;
  padding-left: var(--padding);
  padding-right: var(--padding);
}

nav h5 {
  font-family: "League Spartan", sans-serif;
}

nav ul {
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  width: 25%;
}

nav ul li {
  cursor: pointer;
  color: var(--text);
  font-family: "League Spartan", sans-serif;
}

nav ul:hover li {
  opacity: 0.4;
}

nav ul li:hover {
  opacity: 1;
}

@media screen and (max-width: 1000px) {
  nav ul {
    width: 50%;
  }
}

@media screen and (max-width: 550px) {
  nav {
    padding-left: 2%;
    padding-right: 2%;
  }

  nav ul {
    width: 65%;
  }
}

@media screen and (max-width: 450px) {
  nav div {
    display: block;
    width: 20%;
  }

  nav ul {
    display: none;
  }
}

main {
  padding-left: var(--padding);
  padding-right: var(--padding);
  font-family: "League Spartan", sans-serif !important;
}

/* HERO */
.landing {
  scroll-snap-align: start end;
  scroll-margin-bottom: 50px;
}

.hero {
  display: flex;
  width: 100%;
  height: 85vh;
  align-items: center;
  justify-content: space-around;
  margin-top: 3%;
  padding-left: var(--padding);
  padding-right: var(--padding);
}

.hero-text {
  width: 50%;
  text-transform: uppercase;
  font-family: "League Spartan", sans-serif !important;
  line-height: 5rem;
}

.hero-image {
  max-width: 35%;
  aspect-ratio: 1;
}

.hero-image img {
  height: 100%;
  width: 100%;
}

.hero-text span {
  color: var(--accent);
}

.typing {
  color: #ffc107 !important;
  font-size: 0.65em;
}

.accent {
  color: var(--text) !important;
  font-size: 0.85em;
}

.arrow-container {
  display: flex;
  justify-content: center;
  height: 15vh;
}

.feather-arrow-down-circle {
  animation: arrowHovering 2s ease-in-out infinite;
  -webkit-animation: arrowHovering 2s ease-in-out infinite linear;
}

@keyframes arrowHovering {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@media screen and (max-width: 1100px) {
  .hero-text {
    width: 65%;
  }
}

@media screen and (max-width: 950px) {
  .hero-image {
    display: none;
  }

  .hero-text {
    width: 100%;
  }

  .hero {
    justify-content: center;
  }
}

@media screen and (max-width: 580px) {
  .typing {
    font-size: 0.4em;
  }
}

/* ABOUT PAGE */
.about {
  width: 100%;
  height: 100vh;
  scroll-snap-align: center;
}

.about .about-text {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-top: calc(var(--padding) * 2);
  padding-bottom: 50px;
}

.about div p {
  line-height: 1.2em;
}

.about div h1 {
  color: var(--accent);
}

.about div h3 {
  color: #ffc107;
}

.about .grid-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.grid-wrapper div {
  width: 100px;
  height: 100px;
  display: grid;
  place-content: center;
  margin-top: 25px;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.grid-wrapper div:hover {
  background-color: #222;
}

.grid-wrapper div img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .grid-wrapper div {
    width: 50px;
    margin-top: 5px;
    height: 50px;
  }

  .about {
    padding-top: 5vh;
  }
}

/* WORK PAGE */
.work {
  max-height: 100vh;
  width: 100%;
  scroll-snap-align: center;
}

.work .work-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-top: calc(var(--padding) * 2);
  padding-bottom: 50px;
}

.work h1 {
  color: var(--accent);
}

.work-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

/* CONTACT PAGE */

.contact {
  height: 100vh;
  width: 100%;
  scroll-snap-align: center;
}

.contact .contact-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-top: calc(var(--padding) * 2);
  padding-bottom: 50px;
}

.contact h1 {
  color: var(--accent);
}

.contact p {
  line-height: 1.2em;
}

.contact-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
